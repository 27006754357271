/*
 * Copyright 2013-2023 AFI, Inc. All Rights Reserved.
 */

*:not(.toastui-editor-contents *) {
  font-family: 'Noto Sans KR';
  font-weight: 400;
}

/* fonts */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url(../fonts/NotoSansKR-Light.woff2) format('woff2'),
    url(../fonts/NotoSansKR-Light.woff) format('woff'),
    url(../fonts/NotoSansKR-Light.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/NotoSansKR-Regular.woff2) format('woff2'),
    url(../fonts/NotoSansKR-Regular.woff) format('woff'),
    url(../fonts/NotoSansKR-Regular.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/NotoSansKR-Medium.woff2) format('woff2'),
    url(../fonts/NotoSansKR-Medium.woff) format('woff'),
    url(../fonts/NotoSansKR-Medium.otf) format('opentype');
}
/* global */
div.empty-row-bar {
  width: 100%;
  height: 10px;
}

.clearfix:after {
  content: '';
  display: black;
  clear: both;
}
div#root,
div#root > div,
div#root > div > div.m-grid.m-grid--hor.m-grid--root.m-page {
  height: 100%;
}

body {
  font-family: 'Noto Sans KR';
  font-weight: 400;
}

/* font overwrite */
.m-subheader .m-subheader__title {
  font-family: 'Noto Sans KR';
}
.m--margin-top-20 {
  margin-top: 0 !important;
}
.m-portlet
  .m-portlet__head
  .m-portlet__head-caption
  .m-portlet__head-title
  .m-portlet__head-text {
  font-family: 'Noto Sans KR';
}

/* login */
.m-login.m-login--2 .m-login__wrapper .m-login__container .m-login__logo {
  margin-bottom: 2rem;
}

.row-login-err {
  padding: 10px 0 0 130px;
}

/* layout */
.m-body .m-content {
  padding-top: 5px;
}
.m-content {
  min-width: 1140px;
}
.m-dropdown__wrapper.profile .m-nav .m-nav__item {
  display: inline-block;
  margin-right: 10px;
}
.m-card-user .m-card-user__details {
  padding: 0;
}
span.m-topbar__username button {
  margin-left: 5px;
}
.m-topbar
  .m-topbar__nav.m-nav
  > .m-nav__item
  > .m-nav__link
  .m-topbar__userpic {
  cursor: pointer;
  color: #676c7b;
  display: inline-block;
  vertical-align: top;
  margin-top: 10px;
}
.m-topbar
  .m-topbar__nav.m-nav
  > .m-nav__item
  > .m-nav__link
  .m-topbar__userpic:hover {
  color: #716aca;
}

/* form view */
/* to be deleted */
/* .m-form .m-form__group label.col-form-label {
  line-height: 2.25;
  font-weight: 500;
} */
div.form-group.m-form__group.row {
  margin-bottom: 1.6rem;
}
/* to be deleted ends here */

.form-group.m-form__group.row .form-view {
  padding-top: 0.85rem;
}

.m-form .m-form__group label.col-form-label {
  padding-right: 10px;
}

.m-form.m-form--group-seperator .m-form__group {
  padding-top: 10px;
  padding-bottom: 10px;
}
.m-portlet .m-portlet__head .m-portlet__head-tools {
  height: 100%;
}
.col-form-label.tline {
  line-height: 1.25rem !important;
}
.m-form span.m-form__help {
  display: inline;
}

/* modal */
.modal label.col-form-label {
  text-align: right;
}
.modal .m-form__group span.form_text {
  display: inline-block;
  padding-top: calc(0.65rem + 1px);
  padding-bottom: calc(0.65rem + 1px);
  line-height: 1.25;
}
.modal .modal-footer {
  justify-content: center;
}
.modal table.modal-right th,
.modal table.modal-right td {
  text-align: right;
}
.modal .m-scrollable .m-badge.m-badge--brand {
  margin-bottom: 3px;
}
.modal.confirm i {
  font-size: 8rem !important;
  color: #f4516c;
}
.modal .m-form .m-form__group {
  padding-top: 10px;
  padding-bottom: 10px;
}
.modal .m-form .m-form__group label.col-form-label {
  line-height: 1.15;
}
.modal .col-form-label {
  line-height: 1.15;
}

.modal-xl {
  max-width: 1800px !important;
}

/* datatables overwrite */
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #faf9fc;
}
.table.table-hover thead th {
  background: #f4f3f8;
}
.table .dt-center {
  text-align: center !important;
}
.table .dt-right {
  text-align: right !important;
}
.table th {
  font-weight: 600;
}
.table td {
  height: 33px !important;
}
.table .m-card-user__pic img {
  width: 40px;
  height: 40px;
}

/* dashboard */
.m-widget4 .m-widget4__item .m-widget4__info {
  width: 70%;
}
.m-widget4 .m-widget4__item .m-widget4__ext {
  width: 30%;
  text-align: right;
}
@media screen and (min-width: 1025px) {
  .m-stack__item.m-brand.m-brand--skin-dark.dashboard {
    background: #a7a9b3;
    box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.1);
  }
}

/* billing */
.m-invoice__logo h5.modal-title {
  color: #898b96;
  font-weight: 400;
}
.m-invoice-2 .m-invoice__wrapper .m-invoice__footer {
  margin-top: 2rem;
  padding: 3rem 0;
}
.m-invoice-2
  .m-invoice__wrapper
  .m-invoice__head
  .m-invoice__container
  .m-invoice__logo {
  padding-top: 6rem;
}
.m-invoice-2 .m-invoice__wrapper .m-invoice__body table tbody tr td {
  font-weight: 400;
  line-height: 1.3;
  padding: 1px 0 !important;
}
.modal .table.table-hover tfoot th {
  background: #f4f3f8;
  border-top: 1px solid #716aca;
}
.table.invoice tfoot td,
td.invoice_subtitle {
  padding-top: 1rem;
  padding-left: 0;
  padding-right: 0;
  font-weight: 600;
  font-size: 1.1rem;
}
.m-invoice-2 .m-invoice__wrapper .m-invoice__body table thead tr th {
  font-size: 1.1em;
  border-bottom: 2px solid #f4516c;
  color: #f4516c;
}
.table.invoice tbody td {
  padding-left: 1.1rem !important;
}
.m-invoice-2
  .m-invoice__wrapper
  .m-invoice__body
  table
  tbody
  tr:first-child
  td {
  padding-top: 1rem;
}
tr.invoce_total td {
  font-size: 1.5rem !important;
  border-top: 1px solid #575962;
}
.table.invoice tfoot td:not(:first-child) {
  text-align: right;
}
.m-invoice-2
  .m-invoice__wrapper
  .m-invoice__head
  .m-invoice__container
  .m-invoice__desc {
  padding: 1rem 0;
}
.form-control.m-input.billing {
  display: inline-block;
  width: auto;
}

/* NavItem Collapse */
.m-aside-menu .m-menu__nav .m-menu__item {
  transition: background-color 0.3s;
}
.m-menu__link-text.subText {
  color: #716aca;
  text-align: left;
  padding-left: 44px !important;
}

.m-menu__item.nav-item {
  margin-bottom: 10px;
}

.m-radio-inline > input.credit-custom-month {
  max-width: 35px;
}

.btn.btn-outline-brand {
  margin-left: 15px;
  color: #716aca;
}

.btn-group-in-row {
  right: 2.6%;
  position: absolute;
}

.row-btn-group {
  min-height: 45px;
}

label.number-of-count {
  padding-top: 10px;
  padding-left: 15px;
}

.table-responsive {
  max-width: 80%;
  margin: 20px auto;
}

/* 결제관리 - 현재요금 */

.m-invoice-2 .m-invoice__wrapper .m-invoice__body table tbody tr td.subCharge {
  padding-left: 50px;
}

.m-invoice-2 .m-invoice__wrapper .m-invoice__body table tbody tr.subChargeRow {
  background-color: hsl(171, 26%, 95%);
}

td.clickable-td {
  cursor: pointer;
  color: hsl(244, 48%, 60%);
  font-weight: 500;
}

.clickable.icon {
  cursor: pointer;
  color: hsl(244, 48%, 60%);
  font-weight: 500;
}
.clickable,
.clickable input {
  cursor: pointer;
}

.octicon {
  cursor: pointer;
  color: hsl(244, 48%, 60%);
  font-weight: 500;
}

.border-required {
  border: 1px solid hsl(210deg 14% 83%);
}

div.DraftEditor-root {
  border: 1px solid #ebedf2;
  border-radius: 0.25rem;
  min-height: 500px;
  margin-bottom: 10px;
}

/* date picker styling */
div.DateRangePicker.DateRangePicker_1 {
  width: 100%;
}
div.DateRangePickerInput.DateRangePickerInput_1.DateRangePickerInput__withBorder.DateRangePickerInput__withBorder_2 {
  width: 100%;
  border-color: hsl(223, 21%, 94%);
  height: 43.3px;
}

div.DateRangePickerInput.DateRangePickerInput_1.DateRangePickerInput__disabled.DateRangePickerInput__disabled_2.DateRangePickerInput__withBorder.DateRangePickerInput__withBorder_3 {
  width: 100%;
  border-color: hsl(223, 21%, 94%);
}

button.DateRangePickerInput_calendarIcon,
button.DateRangePickerInput_calendarIcon_1 {
  margin-top: -4px;
  padding-left: 4px;
  padding-right: 4px;
  margin-left: 0;
  float: right;
}

.SingleDatePicker.SingleDatePicker_1 {
  width: 100%;
}

.SingleDatePickerInput.SingleDatePickerInput_1.SingleDatePickerInput__withBorder.SingleDatePickerInput__withBorder_2
  div.DateInput.DateInput_1 {
  width: 66%;
  height: 100%;
}

.SingleDatePickerInput.SingleDatePickerInput_1.SingleDatePickerInput__withBorder.SingleDatePickerInput__withBorder_2 {
  width: 100%;
  height: calc(2.95rem + 2px);
  /* border-color: #ebedf2;*/
  border-color: hsl(223, 21%, 94%);
  color: #575962;
  border-radius: 0.25rem;
}

input.DateInput_input.DateInput_input_1.DateInput_input__small.DateInput_input__small_2 {
  text-align: center;
  line-height: 24px;
  font-size: 14px;
}

input.DateInput_input.DateInput_input_1 {
  text-align: center;
  line-height: 1.8;
  font-size: 14px;
  padding: 7px 11px 4px;
}

button.SingleDatePickerInput_calendarIcon.SingleDatePickerInput_calendarIcon_1 {
  float: right;
}

/* for SELECT BOX */
.for-selectBox {
  padding: 0;
}

div.selectBox.wrapper {
  width: 100%;
}
.selectBox.button {
  text-align: left;
  color: #575962;
  font-family: sans-serif, Arial;
  border-radius: 0.25rem;
  display: block;
  width: 100%;
  height: calc(2.95rem + 2px);
  padding: 0.85rem 1.15rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ebedf2;
  border-radius: 0.25rem;
  transition: border-color 0.15s;
}

.icon.float-right {
  float: right;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.m-btn--icon > span > span {
  padding-left: 0;
  line-height: 18px;
}
.octicon {
  margin-right: 0.2em;
}
.m-header-menu.m-header-menu--skin-light
  .m-menu__nav
  > .m-menu__item
  > .m-menu__link
  .m-menu__link-text {
  vertical-align: top;
  padding-top: 22px;
}
.m-header-menu.m-header-menu--skin-light
  .m-menu__nav
  > .m-menu__item
  > .m-menu__link
  .m-menu__link-icon {
  display: none;
}
.m-subheader__breadcrumbs {
  display: none !important;
}
.m-subheader .m-subheader__title.m-subheader__title--separator {
  border-right: none;
}
.input-group-text {
  display: none;
}
.m-aside-menu .m-menu__nav > .m-menu__item > .m-menu__link {
  padding-top: 11px;
  padding-bottom: 10px;
  padding-left: 0px;
}
.DateInput_input__focused {
  border-bottom: none !important;
}
.page-item.active .page-link {
  background-color: #716aca;
  border-color: #716aca;
}
.m--font-danger {
  color: #564ec0 !important;
}
.m-invoice-2 .m-invoice__wrapper .m-invoice__body table thead tr th {
  color: #564ec0 !important;
  border-bottom: 1px solid #564ec0;
}
.btn-primary {
  background-color: #716aca;
  border: 1px solid #716aca;
}
.btn-primary:hover {
  background-color: #564ec0;
}
.m-badge.m-badge--success {
  color: #4ae1ca;
  background-color: #fff;
  font-weight: 500;
  padding: 0;
  padding-left: 3px;
}
.table th,
.table td {
  padding-left: 0;
}
.modal .m-form__group span.form_text {
  display: inline-block;
  padding-top: calc(0.65rem + 3px);
}
.m-form__group.row .m-checkbox-inline,
.m-form__group.row .m-radio-inline {
  margin-top: 9px;
}
.m-invoice-2
  .m-invoice__wrapper
  .m-invoice__head
  .m-invoice__container
  .m-invoice__logo {
  padding-top: 3rem;
}

div.table-no-display {
  display: none;
}

table {
  width: 100%;
}

.table td {
  height: 42px;
}

table tbody tr:hover {
  background: hsl(0deg 28% 95%);
}

thead tr td th,
tbody tr td th {
  /* thead.main-table.table-scrollable tr td th,
tbody.main-table.table-scrollable tr td th { */
  display: block;
}

thead,
.main-table tr {
  display: block;
}

thead.table-header-group {
  display: table-header-group;
}

tr.fixed-row {
  display: block;
}
td.td-clickable-currency {
  cursor: pointer;
}
tr:after {
  content: ' ';
  display: block;
  visibility: hidden;
  clear: both;
}

tr:focus {
  background: cornflrgb(128, 168, 241);
}

tbody.main-table.table-scrollable {
  max-height: 430px;
  overflow-y: auto;
  display: block;
}
/* tbody.main-table {
  overflow-y: auto;
} */

thead.table-scrollable {
  max-width: 100%;
}

tbody.main-table td,
thead.main-table th {
  width: 10%;
  float: left;
}

tbody.main-table.year-table td,
thead.main-table.year-table th {
  width: 6%;
  float: left;
}

tbody.main-table.year-table td:first-child,
thead.main-table.year-table th:first-child {
  width: 27%;
  float: left;
}

thead.column-more-than-10 th,
tbody.column-more-than-10 td {
  width: 8%;
  float: left;
}

thead.column-9 th,
tbody.column-9 td {
  width: 10%;
  float: left;
}

thead.column-10 th,
tbody.column-10 td {
  width: 8%;
  float: left;
}
.general-account-table th:first-child,
.general-account-table td:first-child {
  width: 11%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

thead.column-8 th,
tbody.column-8 td {
  width: 12.5%;
  /* float: left; */
}

thead.column-5 th,
tbody.column-5 td {
  width: 20%;
  float: left;
}

/* credit 테이블 추가 */
thead.credit-table th:last-child,
tbody.credit-table td:last-child {
  min-height: 42px;
}

thead.credit-table th:nth-child(3) {
  width: 45%;
}
thead.credit-table th:nth-child(4) {
  width: 10%;
}
thead.credit-table th:nth-child(5) {
  width: 5%;
}

tbody.credit-table td:nth-child(3) {
  width: 45%;
}
tbody.credit-table td:nth-child(4) {
  width: 10%;
}
tbody.credit-table td:nth-child(5) {
  width: 5%;
}
/*  */

thead.column-4 th,
thead.column-4-payperuse th,
tbody.column-4 td {
  width: 25%;
  float: left;
}

thead.column-4 th {
  width: 178px !important;
}

thead.column-6 th,
tbody.column-6 td {
  width: 14%;
  float: left;
}

thead.column-6 th:last-child,
tbody.column-6 td:last-child {
  min-height: 42px;
}

thead.sentMails th:first-child,
tbody.sentMails td:first-child {
  width: 24.4% !important;
}
thead.sentMails th:nth-child(2),
tbody.sentMails td:nth-child(2) {
  width: 17% !important;
}

thead.sentMails th:nth-child(3),
tbody.sentMails td:nth-child(3),
thead.sentMails th:nth-child(4),
tbody.sentMails td:nth-child(4),
thead.sentMails th:nth-child(5),
tbody.sentMails td:nth-child(5) {
  width: 15% !important;
}
thead.sentMails th:last-child,
tbody.sentMails td:last-child {
  width: 13.2% !important;
}

/* 계정 관리 > 탈퇴 계정 ONLY */
thead.column-5.leave-account-table th:nth-child(2),
tbody.column-5.leave-account-table td:nth-child(2) {
  text-align: center;
}

thead.column-5.leave-account-table th:nth-child(3),
tbody.column-5.leave-account-table td:nth-child(3) {
  text-align: center;
}

thead.column-4 th,
tbody.column-4 td {
  width: 25%;
  float: left;
}
/* 계정 관리 > 탈퇴 계정 ONLY ends here */

/* 테이블별 스타일링 */
/* 메일 관리 테이블 */
.mails th:nth-child(1),
.mails td:nth-child(1) {
  width: 30%;
}
.mails th:nth-child(2),
.mails td:nth-child(2) {
  width: 35%;
}
.mails th:nth-child(3),
.mails td:nth-child(3) {
  width: 33%;
  min-height: 42px;
}
/* 요금제 관리 테이블-base */
.indicators th:nth-child(1),
.indicators td:nth-child(1) {
  width: 30%;
}
.indicators th:nth-child(2),
.indicators td:nth-child(2) {
  width: 30%;
  text-align: right;
}
.indicators th:nth-child(3),
.indicators td:nth-child(3) {
  width: 38%;
  min-height: 42px;
  text-align: right;
}
/* 요금제 관리 테이블-func */
.indicators-func th:nth-child(1),
.indicators-func td:nth-child(1) {
  width: 20%;
}
.indicators-func th:nth-child(2),
.indicators-func td:nth-child(2) {
  width: 20%;
  text-align: right;
}
.indicators-func th:nth-child(3),
.indicators-func td:nth-child(3) {
  width: 20%;
  text-align: right;
}
.indicators-func th:nth-child(4),
.indicators-func td:nth-child(4) {
  width: 20%;
  text-align: right;
}
.indicators-func th:nth-child(5),
.indicators-func td:nth-child(5) {
  width: 20%;
  min-height: 42px;
  text-align: right;
}
/* 요금제 관리 테이블-chat */
.indicators-chat th:nth-child(1),
.indicators-chat td:nth-child(1) {
  width: 20%;
}
.indicators-chat th:nth-child(2),
.indicators-chat td:nth-child(2) {
  width: 20%;
  text-align: right;
}
.indicators-chat th:nth-child(3),
.indicators-chat td:nth-child(3) {
  width: 20%;
  text-align: right;
}
.indicators-chat th:nth-child(4),
.indicators-chat td:nth-child(4) {
  width: 20%;
  text-align: right;
}
.indicators-chat th:nth-child(5),
.indicators-chat td:nth-child(5) {
  width: 20%;
  min-height: 42px;
  text-align: right;
}
/* 요금제 관리 테이블-match */
.indicators-match th:nth-child(1),
.indicators-match td:nth-child(1) {
  width: 14%;
}
.indicators-match th:nth-child(2),
.indicators-match td:nth-child(2) {
  width: 14%;
  text-align: right;
}
.indicators-match th:nth-child(3),
.indicators-match td:nth-child(3) {
  width: 14%;
  text-align: right;
}
.indicators-match th:nth-child(4),
.indicators-match td:nth-child(4) {
  width: 14%;
  text-align: right;
}
.indicators-match th:nth-child(5),
.indicators-match td:nth-child(5) {
  width: 14%;
  text-align: right;
}
.indicators-match th:nth-child(6),
.indicators-match td:nth-child(6) {
  width: 14%;
  text-align: right;
}
.indicators-match th:nth-child(7),
.indicators-match td:nth-child(7) {
  width: 14%;
  min-height: 42px;
  text-align: right;
}
/* 일반 계정 테이블 */
.table td {
  height: 42px;
}

.general-account-table.table-scrollable thead tr {
  width: 99.3%;
}

.general-account-table thead.column-10 th:nth-child(1),
.general-account-table tbody.column-10 td:nth-child(1) {
  width: 14%;
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
}

.general-account-table thead.column-10 th:nth-child(2),
.general-account-table tbody.column-10 td:nth-child(2) {
  width: 6%;
}
.general-account-table thead.column-10 th:nth-child(3),
.general-account-table tbody.column-10 td:nth-child(3) {
  width: 8%;
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
}

.general-account-table thead.column-10 th:nth-child(4),
.general-account-table tbody.column-10 td:nth-child(4) {
  width: 7%;
}

.general-account-table thead.column-10 th:nth-child(5),
.general-account-table tbody.column-10 td:nth-child(5) {
  width: 5%;
  text-align: right;
}

.general-account-table thead.column-10 th:nth-child(6),
.general-account-table tbody.column-10 td:nth-child(6) {
  width: 6%;
  text-align: right;
}

.general-account-table thead.column-10 th:nth-child(7),
.general-account-table tbody.column-10 td:nth-child(7) {
  width: 6%;
  text-align: right;
}

.general-account-table thead.column-10 th:nth-child(8),
.general-account-table tbody.column-10 td:nth-child(8) {
  width: 10%;
  text-align: right;
}

.general-account-table thead.column-10 th:nth-child(9),
.general-account-table tbody.column-10 td:nth-child(9) {
  width: 8%;
  margin-left: 12px;
}

.general-account-table thead.column-10 th:nth-child(10),
.general-account-table tbody.column-10 td:nth-child(10) {
  width: 8%;
}

.general-account-table thead.column-10 th:nth-child(11),
.general-account-table tbody.column-10 td:nth-child(11) {
  width: 5%;
}

.general-account-table tbody.column-10 td:nth-child(4),
.general-account-table tbody.column-10 td:nth-child(5),
.general-account-table tbody.column-10 td:nth-child(6),
.general-account-table tbody.column-10 td:nth-child(7) {
  padding-right: 0;
}

.projectList-table thead tr {
  width: 99.4%;
}

.projectList-table th:nth-child(1),
.projectList-table td:nth-child(1):not([colspan]) {
  width: 2%;
}

.projectList-table th:nth-child(2),
.projectList-table td:nth-child(2) {
  width: 12%;
}

.projectList-table th:nth-child(3),
.projectList-table td:nth-child(3) {
  width: 7%;
}

.projectList-table th:nth-child(4),
.projectList-table td:nth-child(4) {
  width: 4%;
}

.projectList-table th:nth-child(5),
.projectList-table td:nth-child(5) {
  width: 11%;
}

.projectList-table th:nth-child(6),
.projectList-table td:nth-child(6) {
  width: 11%;
}

.projectList-table th:nth-child(7),
.projectList-table td:nth-child(7) {
  width: 9%;
}

.projectList-table th:nth-child(8),
.projectList-table td:nth-child(8) {
  width: 9%;
}

.projectList-table th:nth-child(9),
.projectList-table td:nth-child(9) {
  width: 5%;
}

.projectList-table th:nth-child(10),
.projectList-table td:nth-child(10) {
  width: 8%;
}

.projectList-table th:nth-child(11),
.projectList-table td:nth-child(11) {
  width: 5%;
}

.projectList-table th:nth-child(12),
.projectList-table td:nth-child(12) {
  width: 5%;
}

.projectList-table th:nth-child(13),
.projectList-table td:nth-child(13) {
  width: 5%;
}

.projectList-table th:nth-child(14),
.projectList-table td:nth-child(14) {
  width: 4%;
}
.projectList-table th:nth-child(15),
.projectList-table td:nth-child(15) {
  width: 3%;
  min-height: 42px;
}

.main-table.statistics-indicator th,
.main-table.statistics-indicator td {
  text-align: right;
  width: 9%;
}
.main-table.statistics-indicator td {
  padding-right: 0;
}
.statistics-indicator th:nth-child(1),
.statistics-indicator td:nth-child(1) {
  text-align: left;
}
.statistics-project th,
.statistics-project td {
  text-align: right;
  width: 11% !important;
}

.statistics-projects th,
.statistics-projects td {
  width: 25% !important;
}

.statistics-project td {
  padding-right: 0;
}
.statistics-project th:nth-child(1),
.statistics-project td:nth-child(1) {
  text-align: left;
}

/* 일반계정 - 계정 상세 정보 */
.account-detail-memo thead.column-4 th:nth-child(4),
.account-detail-memo tbody.column-4 td:nth-child(4) {
  text-align: right;
}
/* 공지사항 관리 테이블 */
.notice-list-table.table-scrollable thead tr {
  width: 99.3%;
}
.main-table.notice-list-table th,
.main-table.notice-list-table td {
  width: 8%;
}
.main-table.notice-list-table th:nth-child(2),
.main-table.notice-list-table td:nth-child(2) {
  width: 20%;
}
.main-table.notice-list-table th:nth-child(4),
.main-table.notice-list-table td:nth-child(4) {
  width: 20%;
}

.main-table.notice-list-table th:last-child,
.main-table.notice-list-table td:last-child {
  min-height: 42px;
}

tr.fixed-row {
  background-color: hsl(0, 0%, 98%);
}
/* 현재요금 테이블 */
thead.main-table.currentCharge-table th {
  width: 16.6%;
}

tbody.main-table.currentCharge-table td {
  width: 16.6%;
}
thead.main-table.currentCharge-table th:nth-child(1) {
  width: 20.6%;
}

tbody.main-table.currentCharge-table td:nth-child(1) {
  width: 20.6%;
}
thead.main-table.currentCharge-table th:nth-child(2) {
  width: 20.6%;
}

tbody.main-table.currentCharge-table td:nth-child(2) {
  width: 20.6%;
}
thead.main-table.currentCharge-table th:nth-child(3) {
  width: 8.6%;
}

tbody.main-table.currentCharge-table td:nth-child(3) {
  width: 8.6%;
}

thead.main-table.currentCharge-table th:nth-child(3) {
  text-align: right;
}
thead.main-table.currentCharge-table th:nth-child(4) {
  text-align: center;
}
thead.main-table.currentCharge-table th:nth-child(5) {
  text-align: right;
}
thead.main-table.currentCharge-table th:nth-child(6) {
  text-align: right;
}
tbody.main-table.currentCharge-table td:nth-child(3) {
  text-align: right;
}
tbody.main-table.currentCharge-table td:nth-child(4) {
  text-align: center;
}
tbody.main-table.currentCharge-table td:nth-child(5) {
  text-align: right;
}
tbody.main-table.currentCharge-table td:nth-child(6) {
  text-align: right;
}

.current-charge-detail.table thead.main-table tr th,
.current-charge-detail.table tbody.main-table tr td {
  width: 16.6%;
  line-height: 50%;
}
.current-charge-detail-width-25.table thead.main-table tr th,
.current-charge-detail-width-25.table tbody.main-table tr td {
  width: 25%;
  line-height: 50%;
}

.current-charge-detail.table tr.subTotal-row,
.current-charge-detail-width-25.table tr.subTotal-row {
  background-color: #ebedf2;
}
.current-charge-detail.table tr,
.current-charge-detail-width-25.table tr {
  height: 45px;
}

table.bill-detail-modal-sub-table td {
  text-align: center;
  border: 1px solid;
}

table.bill-detail-modal-sub-table td:nth-child(2) {
  text-align: right;
  background-color: hsl(60, 28%, 94%);
}
table.bill-detail-modal-sub-table td:nth-child(4) {
  text-align: right;
  background-color: hsl(60, 28%, 94%);
}
table.bill-detail-modal-sub-table td:nth-child(6) {
  text-align: right;
  background-color: hsl(60, 28%, 94%);
}
table.bill-detail-modal-sub-table td:nth-child(8) {
  text-align: right;
  background-color: hsl(60, 28%, 94%);
}

tr.last-row-enterprise-sumResult-table td {
  width: 12.5% !important;
}
/* tr.last-row-enterprise-sumResult-table td:nth-child(2) {
  width: 5% !important;
}
tr.last-row-enterprise-sumResult-table td:nth-child(7) {
  width: 17.5% !important;
}
tr.last-row-enterprise-sumResult-table td:nth-child(8) {
  width: 15% !important;
} */

table.bill-detail-modal-sub-table.sub-dau-table td:nth-child(1),
table.bill-detail-modal-sub-table.sub-dau-table td:nth-child(3),
table.bill-detail-modal-sub-table.sub-dau-table td:nth-child(5) {
  width: 10%;
  padding-left: 10px;
}
table.bill-detail-modal-sub-table.sub-dau-table td:nth-child(2),
table.bill-detail-modal-sub-table.sub-dau-table td:nth-child(4),
table.bill-detail-modal-sub-table.sub-dau-table td:nth-child(6) {
  width: 11%;
}
/* sdfsdfsdfasdfasdfasdfsdf */

table.bill-detail-modal-sub-table.sub-share-table td:nth-child(1),
table.bill-detail-modal-sub-table.sub-share-table td:nth-child(3),
table.bill-detail-modal-sub-table.sub-share-table td:nth-child(5) {
  width: 10%;
  padding-left: 10px;
}
table.bill-detail-modal-sub-table.sub-share-table td:nth-child(2),
table.bill-detail-modal-sub-table.sub-share-table td:nth-child(4),
table.bill-detail-modal-sub-table.sub-share-table td:nth-child(6) {
  width: 11%;
}

table.bill-detail-modal-sub-table.sub-db-table td:nth-child(1),
table.bill-detail-modal-sub-table.sub-db-table td:nth-child(3),
table.bill-detail-modal-sub-table.sub-db-table td:nth-child(5),
table.bill-detail-modal-sub-table.sub-db-table td:nth-child(7) {
  width: 8%;
  padding-left: 10px;
}
table.bill-detail-modal-sub-table.sub-db-table td:nth-child(2),
table.bill-detail-modal-sub-table.sub-db-table td:nth-child(4),
table.bill-detail-modal-sub-table.sub-db-table td:nth-child(6),
table.bill-detail-modal-sub-table.sub-db-table td:nth-child(8) {
  width: 12%;
  line-height: 2.5;
}

table.bill-detail-modal.dau-table th,
table.bill-detail-modal.dau-table td {
  width: 14%;
  text-align: right;
}

table.bill-detail-modal th,
table.bill-detail-modal td {
  text-align: right;
}

table.bill-detail-modal th:first-child,
table.bill-detail-modal td:first-child {
  text-align: center;
}

/* 청구내역 테이블 */
.table.chargedBill-table thead th:first-child,
.table.chargedBill-table tbody td:first-child {
  width: 12%;
}
.table.chargedBill-table thead th:nth-child(2),
.table.chargedBill-table tbody td:nth-child(2) {
  width: 12%;
}

/* .table.chargedBill-table thead th:nth-child(3),
.table.chargedBill-table tbody td:nth-child(3) {
  text-align: right;
  width: 8%;
}
.table.chargedBill-table thead th:nth-child(4),
.table.chargedBill-table tbody td:nth-child(4) {
  text-align: right;
  width: 8%;
}
.table.chargedBill-table thead th:nth-child(5),
.table.chargedBill-table tbody td:nth-child(5) {
  text-align: right;
  width: 8%;
}
.table.chargedBill-table thead th:nth-child(6),
.table.chargedBill-table tbody td:nth-child(6) {
  text-align: right;
  width: 8%;
}
.table.chargedBill-table thead th:nth-child(7),
.table.chargedBill-table tbody td:nth-child(7) {
  width: 8%;
  text-align: right;
}
.table.chargedBill-table thead th:nth-child(8),
.table.chargedBill-table tbody td:nth-child(8) {
  width: 9%;
  text-align: center;
} */
.table.chargedBill-table thead th:nth-child(6),
.table.chargedBill-table tbody td:nth-child(6) {
  /* text-align: right; */
  width: 7%;
}

.table.chargedBill-table tbody td,
.table.chargedBill-table thead th {
  width: 6.2%;
}

.table.chargedBill-table thead th:last-child,
.table.chargedBill-table tbody td:last-child {
  height: 43.5px;
}

/* 연체요금 테이블 */
.overdue-bill-table th:nth-child(1),
.overdue-bill-table td:nth-child(1) {
  width: 18%;
}
.overdue-bill-table th:nth-child(3),
.overdue-bill-table td:nth-child(3) {
  text-align: right;
}
.overdue-bill-table th:nth-child(4),
.overdue-bill-table td:nth-child(4) {
  text-align: center;
}
.overdue-bill-table th:nth-child(5),
.overdue-bill-table td:nth-child(5) {
  text-align: center;
}
.overdue-bill-table th:nth-child(6),
.overdue-bill-table td:nth-child(6) {
  width: 15.6%;
}

/* 통계 - 매출 테이블 */

.sales-table th,
.sales-table td {
  width: 8.3% !important;
}

.theBackendSales-table th,
.theBackendSales-table td {
  width: 14.27% !important;
  text-align: right;
}

.theBackendSales-table th:nth-child(1),
.theBackendSales-table td:nth-child(1) {
  text-align: left;
}

.sales-table th:first-child,
.sales-table td:first-child {
  width: 13% !important;
}
.sales-table th:nth-child(2),
.sales-table td:nth-child(2) {
  width: 10.3% !important;
}
.sales-table th:nth-child(6),
.sales-table td:nth-child(6),
.sales-table th:nth-child(7),
.sales-table td:nth-child(7) {
  width: 6.3% !important;
}
.sales-table th:nth-child(9),
.sales-table td:nth-child(9) {
  width: 5.6% !important;
}

table.sales-table td:nth-child(5) {
  padding-right: 20px;
}

/* 쿠폰 테이블 */
table.couponList-table th:last-child {
  min-height: 42px;
}

table.coupon-details-table th:nth-child(1),
table.coupon-details-table td:nth-child(1) {
  width: 32%;
}
table.coupon-details-table th:nth-child(2),
table.coupon-details-table td:nth-child(2) {
  width: 32%;
}
table.coupon-details-table th:nth-child(3),
table.coupon-details-table td:nth-child(3) {
  width: 18%;
}
table.coupon-details-table th:nth-child(4),
table.coupon-details-table td:nth-child(4) {
  width: 18%;
}

tbody.coupon-details-table.table-scrollable {
  max-height: 325px;
}

/* 쇼케이스 테이블 */
.showcase-table.column-6 th:first-child,
.showcase-table.column-6 td:first-child,
.showcase-table.column-6 th:nth-child(2),
.showcase-table.column-6 td:nth-child(2) {
  max-width: 1%;
  min-height: 43.5px;
}

.showcase-table th:last-child {
  min-height: 42px;
}

.showcase-table.column-6 th:nth-child(2),
.showcase-table td:nth-child(2) {
  max-width: 6% !important;
}

.showcase-table th:nth-child(3),
.showcase-table td:nth-child(3) {
  width: 18%;
}

.showcase-table td span {
  line-height: 2.3;
}

/* 팝업 테이블 */
.popupList-table th:first,
table.popupList-table tbody.popupList-table tr td:nth-child(1) {
  width: 20px;
}
.popupList-table th:last-child {
  min-height: 42px;
}

/* 콘솔 팝업 */
.consolePopupList-table.table-scrollable thead tr {
  width: 99.3%;
}

thead.consolePopupList-table th:nth-child(1),
tbody.consolePopupList-table td:nth-child(1),
thead.consolePopupList-table th:nth-child(2),
tbody.consolePopupList-table td:nth-child(2) {
  width:15%
}

thead.consolePopupList-table th:nth-child(3),
tbody.consolePopupList-table td:nth-child(3) {
  width: 14%;
}

thead.consolePopupList-table th:nth-child(5),
tbody.consolePopupList-table td:nth-child(5) {
  width: 18%;
}
thead.consolePopupList-table th:nth-child(6),
tbody.consolePopupList-table td:nth-child(6) {
  width: 5%;
}
thead.consolePopupList-table th:nth-child(7),
tbody.consolePopupList-table td:nth-child(7) {
  width: 8%;
}
thead.consolePopupList-table th:nth-child(8),
tbody.consolePopupList-table td:nth-child(8) {
  width: 5%;
}
thead.consolePopupList-table th:nth-child(9),
tbody.consolePopupList-table td:nth-child(9) {
  width: 4%;
}
thead.consolePopupList-table th:last-child,
tbody.consolePopupList-table td:last-child {
  min-height: 42px;
  width: 5%;
  text-align: center;
}

/* popover */
.popover.show.bs-popover-left {
  max-width: 340px;
}

.column-6.sleeping-candidates-table th:last-child {
  min-height: 42px;
}

/* retention-table */
thead.retention-table th,
tbody.retention-table td {
  width: 9%;
}

.retention-table th,
.retention-table td {
  border-top: none !important;
}

.retention-table tr {
  border: 1px solid hsl(225deg 22% 96%);
}

/* 테이블별 스타일링 ends here */

span.sortingIconSet {
  height: 15px;
  display: inline-block;
  width: 12px;
  margin-left: 3px;
  position: relative;
}

span.sortingIcon {
  position: absolute;
  cursor: pointer;
}

svg.octicon {
  height: 12px;
}

.error.message {
  color: #b358f9 !important;
  font-size: 0.857rem;
  font-weight: 300;
  position: absolute;
  margin-top: 5px;
}
.error.message.visible {
  display: block !important;
}
.error.message.visible.popupErr {
  position: relative;
  left: 17%;
}

.form-group.m-form__group.row.mt-30.popupDate {
  margin-bottom: 0;
}

.form-group.m-form__group.row.mt-30.popupImg {
  margin-bottom: 4rem;
}

.mt-30 {
  margin-top: 30px;
}

.modal-body .table {
  width: 95%;
  margin: 0 auto;
}

div.showMore {
  margin: 0 auto;
  width: 80px;
}
div.showMore-hidden {
  margin: 0 auto;
  width: 80px;
  visibility: hidden;
}

/* 출시 프로젝트 모달 (테이블) */

.released-projects th:nth-child(1),
.released-projects td:nth-child(1) {
  width: 36%;
}
.released-projects th:nth-child(2),
.released-projects td:nth-child(2) {
  width: 34%;
}
.released-projects th:nth-child(3),
.released-projects td:nth-child(3) {
  width: 30%;
}

/* draftEditor */
.rdw-link-modal {
  height: 250px;
}

div.isImportant {
  display: inline-block;
}

div.isImportant input {
  margin-right: 7px;
}

/* left-side menu */
.clickedSubMenu {
  color: hsl(56, 49%, 51%) !important;
  background-color: hsl(0, 24%, 23%) !important;
}

.clickedMenu {
  background-color: hsl(208, 81%, 24%) !important;
}

div.btn.toggle.btn-xs.btn-primary,
div.btn.toggle.btn-xs.off.btn-danger {
  height: 100%;
  background: none;
  border: none;
  float: right;
}

div.toggle-group {
  height: 50%;
}

div.toggle-group > span.btn.toggle-on.btn-xs.btn-primary {
  background-color: greenyellow;
  border: 1px solid hsl(0, 0%, 96%);
  border-radius: 9px 10px 10px 10px;
}
div.toggle-group > span.btn.toggle-off.btn-xs.btn-primary {
  border-radius: 10px 8px 11px 10px;
}

div.toggle-group > span.btn.toggle-on.btn-xs.btn-danger {
  background-color: red;
  border-color: hsl(0, 0%, 96%);
}
div.toggle-group > span.btn.toggle-off.btn-xs.btn-danger {
  background-color: red;
  border-color: hsl(0, 0%, 96%);
  border-radius: 10px 8px 11px 10px;
}
.toggle-handle.btn.btn-xs.btn-default {
  border-radius: 10px 10px 10px 10px;
}

span.btn.toggle-on.btn-xs,
span.btn.toggle-off.btn-xs {
  height: 100%;
}

.no-padding {
  padding: 0 !important;
}

p.msg-modal-description {
  text-align: center;
  font-size: 1.5rem;
  line-height: 1.7;
}

div.m-radio-inline-update-pay-plan {
  padding-left: 15px;
}

div.update-pay-plan-changingRate {
  /* max-width: 29.2%; */
  padding-right: 0;
}

div.update-pay-plan-changingRate > input {
  width: 210px;
}
div.col-form-label.update-pay-plan-changingRate {
  padding-left: 0;
  display: inline-block;
}

.label-view {
  min-width: 200px !important;
  text-align: left !important;
}

input.update-pay-plan-input {
  display: inline-block;
  width: 89.4%;
}

div.update-pay-plan-label {
  display: inline;
  margin-left: 0;
  padding-left: 5px;
}

div.changingRateLimit {
  line-height: 3;
}

div.col-update-pay-plan {
  padding-left: 15px;
  padding-right: 5px;
}

div.store-exception {
  padding-top: 13px;
}

div.store-exception > div.btn.toggle.btn-xs.btn-primary,
div.store-exception > div.btn.toggle.btn-xs.off.btn-danger {
  float: left;
}

div.form-group.m-form__group.row label.update-pay-plan-changingRate {
  text-align: left;
}

/* image upload */
.upload.button {
  outline: none;
  background-color: #fff;
  width: 150px;
  height: 150px;
  border: 1px dashed #cccccc;
  display: inline-block;
  margin-right: 15px;
}

.upload.button:hover i {
  color: #bdbdbd;
}
.upload.button:hover {
  border: 1px dashed #bdbdbd;
}
.upload.button i {
  margin: 0;
}
.uploaded.image {
  position: relative;
  width: 150px;
  height: 150px;
  border: 1px dashed #cccccc;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
  background-size: cover;
  background-position: center center;
}
.uploaded.image img {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9;
}
.uploaded.image i {
  cursor: pointer;
  position: absolute;
  z-index: 10;
  right: 0;
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
}
.uploaded.image i:hover {
  color: rgba(255, 255, 255, 1);
}

div.imgSize {
  position: relative;
  width: 150px;
  height: 150px;
  overflow: hidden;
  vertical-align: middle;
  line-height: 22;
  display: inline-block;
}

div.landing-img-container {
  margin-left: 15px;
  max-height: 152px;
}

div.terms-button-group {
  margin: 40px 44%;
}

.lt-margin-30 {
  margin-left: 30px;
}

input.birthdaytime {
  width: 49.19%;
  display: inline-block;
}

input.birthdaytime-mr-10 {
  margin-right: 10px;
}

button.plus-gameBill {
  height: 17px;
  display: inline-flex;
  margin-left: 10px;
  background-color: #716aca;
  border: none;
  vertical-align: text-bottom;
}

svg.plus-gameBill {
  color: white;
}

.required.form-group.m-form__group.row > label:after {
  color: #b358f9 !important;
  margin: 0 0 0 2px !important;
}

div.upAndDown-triangle {
  display: inline-block;
  margin-left: 10px;
  line-height: 3;
  vertical-align: middle;
}

div.toggle-class-or-doc-hidden {
  display: none;
}

div.toggle-class-or-doc-display {
  display: none;
}

div.dev-getStarted-subItems,
div.dev-SdkDocs-subItems {
  margin-left: 20px;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 0.7rem !important;
  margin-bottom: 0;
  font-size: 1.5rem !important;
  color: #6c757d;
  white-space: nowrap;
}

/* SDK문서 */
.versionList-select {
  display: inline-block;
  width: 20%;
}

.error-wrapper {
  margin-left: 129px;
  height: 28px;
}

/* 개발자 문서 등록 모달에서 소분류 선택 드롭다운 메뉴 */
.dropdown-menu.show {
  max-height: 500px;
  overflow-y: scroll;
}

/* landing 문의관련 */

textarea.landing-inquiry {
  height: 200px;
  width: 90%;
}

div.form-control.landing-inquiry {
  height: 100% !important;
  width: 90%;
}

.file-attachmentKey {
  max-width: 24%;
}

.h4.project-detail {
  padding-left: 28px;
}

.h4.project-detail:first-child {
  margin-top: 14px;
}

.col-lg-3.m--margin-bottom-10-tablet-and-mobile.flex-20 {
  flex: 0 0 20%;
}

.col-lg-10.flex {
  display: flex;
}
.col-lg-10.flex .popup-important {
  display: flex;
  align-self: center;
}

.popup-important input {
  margin-right: 10px;
}

.textArea-wrapper {
  width: 39%;
  height: 100%;
  padding-left: 62px;
}

textarea.textArea {
  height: 800px;
  width: 100%;
  padding: 25px;
}

.btn-wrapper-In-textArea-wrapper > button {
  width: 100%;
  height: 100%;
  padding: 0 !important;
}
.btn-wrapper-In-textArea-wrapper > button:nth-child(2) {
  margin-top: 20px;
}
.btn-wrapper-In-textArea-wrapper {
  width: 70px;
  height: 50px;
  margin: 400px 0px;
  margin-left: 45px;
  margin-right: -11px;
}

.div-justfy-content {
  justify-content: center;
}
.div-justfy-content > button {
  margin-right: 20px;
}

.div-justfy-content > button:last-child {
  border: 1px solid hsl(244deg 48% 60%);
}

div.file-uploader > ul {
  display: inline-block !important;
  width: 91%;
  border: 1px solid hsl(210deg 14% 83%);
  border-radius: 0.25rem;
  min-height: 41px;
}

div.file-uploader label {
  margin-bottom: 0 !important;
}

div.file-uploader > ul > li {
  margin: 10px;
}

div.file-uploader > input:last-child {
  display: none;
}

.sentMailDetail {
  max-width: 850px !important;
}

.sentMailDetail .modal-body a {
  display: flex !important;
}

table.sentMailDetail {
  margin-bottom: 23px !important;
  margin-top: 40px !important;
}

.dashboard-stat-wrapper {
  margin-right: 3%;
  margin-bottom: 3%;
  display: inline-block;
  width: 17%;
  border: 1px solid;
  height: 130px;
}

.dashboard-stat-wrapper > .ui.statistic {
  width: 100%;
  margin-top: 22px !important;
  /* margin-bottom: 10px !important; */
}

.dashboard-stat-wrapper > .ui.statistic > .label {
  font-size: 1.7em;
  font-weight: 500;
  margin-bottom: 25px;
}

.dashboard-stat-wrapper > .ui.statistic > .value {
  font-size: 2rem !important;
  font-weight: 600;
}

.dashboard-stat-wrapper > .ui.statistic > .label,
.dashboard-stat-wrapper > .ui.statistic > .value {
  text-transform: none !important;
  text-align: left !important;
  padding-left: 20px;
}

.dashboard-stats-container {
  margin-top: 90px;
  position: relative;
}

.dashboard-stat-wrapper.bigger {
  width: 37%;
  height: 282px;
}

.dashboard-stat-wrapper.bigger .value > span:first-child {
  display: inline-block;
  width: 140px;
  text-align: left;
  margin-right: 20px;
  margin-bottom: 15px;
}

.dashboard-stat-wrapper.bigger .value.total > span {
  font-size: 2rem;
  font-weight: 600;
  width: 200px;
  margin-bottom: 45px;
}

.dashboard-stat-wrapper.bigger .value > span:nth-child(2) {
  width: 150px;
}

.dashboard-stat-wrapper.wider {
  width: 37%;
}

.ui.table thead {
  display: table-header-group !important;
}

.dashboard-table-container {
  display: inline-block;
  width: 46.7%;
  margin-right: 50px;
}

.dashboard-table-container .m-radio-inline {
  float: right;
}

.data-extraction-container {
  display: flex;
}

.data-extraction-container
  .item:not(.data-extraction-container .item:first-child) {
  margin-left: 156px;
}

.data-extraction-container .item div:first-child {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #3f4046;
  margin-bottom: 8px;
}

.data-extraction-container .item button {
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: #000000;
}

.payment.subCategory {
  padding-left: 1.9%;
  font-weight: 500;
}

.col-lg-2-indicators {
  flex: 1 0 16.66667%;
  max-width: 35%;
  text-align: left !important;
}

.col-lg-2-no-flex {
  max-width: 35%;
  text-align: left !important;
}

.form-view-no-padd-top {
  padding-top: 0 !important;
}

.radio-inline-wrapper .m-radio-inline {
  margin-top: 0 !important;
}

.checkbox-wrapper div input {
  width: 10%;
}

.checkbox-wrapper .isImportant {
  width: 100%;
}

/* 할인 적용 */
.disabled {
  color: #ccc !important;
  cursor: default;
  pointer-events: none;
}

.discountModalYear {
  display: inline-block;
  margin: 0 10px 10px 10px;
}

.discountModalMonthForm {
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  grid-auto-flow: column;
}

/* 종량제 */
/* 상세 내역 모달 */
/* 상세 내역 모달 테이블 */
.show-subCategory {
  background: rgba(36, 87, 138, 0.05);
  display: table-row;
}

.no-subCategory {
  display: none !important;
}

.padd-left-10 {
  padding-left: 14px !important;
}

.flex-container.year-month {
  display: flex;
  margin: 10px;
}

.flex-item.year-month {
  flex-grow: 0.02;
}

.charge-summary-container1 {
  width: 41.1%;
  margin-top: 40px;
}
.charge-summary-container2 {
  width: 40%;
  margin-top: 5px;
}

.charge-summary-row1,
.charge-summary-row2 {
  display: flex;
  justify-content: space-between;
}


.main-table th, .main-table td {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
